import React, { useRef } from "react";
import "../Gellery/Gellery.scss";
import "../Gellery/GelleryAdaptive.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation } from "swiper/modules";

const images = [
  process.env.PUBLIC_URL + "/gellery1.jpeg",
  process.env.PUBLIC_URL + "/gellery2.jpeg",
  process.env.PUBLIC_URL + "/gellery3.jpeg",
  process.env.PUBLIC_URL + "/gellery4.jpeg",
  process.env.PUBLIC_URL + "/gellery5.jpeg",
  process.env.PUBLIC_URL + "/gellery6.jpeg",
  process.env.PUBLIC_URL + "/gellery7.jpeg",
];

const Gellery: React.FC = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <div className="gallery">
      <div className="gallery_body">
        <div className="gallery__title-container">
          <h2 className="gallery__title">
            <span>Га</span>лерея
          </h2>
        </div>
        <div className="gallery__slider__body">
          <Swiper
            speed={500}
            effect={"coverflow"}
            centeredSlides={true}
            initialSlide={1}
            direction={"horizontal"}
            slidesPerView={"auto"}
            spaceBetween={30}
            coverflowEffect={{
              rotate: 5,
              stretch: 10,
              depth: 160,
              modifier: 1,
              slideShadows: false,
            }}
            slideToClickedSlide={true}
            lazyPreloadPrevNext={2}
            pagination={{
              clickable: true,
            }}
            className="gallery__slider"
            loop={true}
            modules={[EffectCoverflow, Navigation]}
            navigation={{
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {images.map((item, i) => (
              <SwiperSlide className="gallery_body">
                <div className="gallery_item">
                  <img src={item} alt="" key={i} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="gallery_nav_body">
          <div className="gallery__navigation">
            <button ref={navigationPrevRef}>
              <svg width="100" height="25" viewBox="0 0 52 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 8.49992L0.646448 8.14636L0.292893 8.49991L0.646445 8.85347L1 8.49992ZM51 8.99992C51.2761 8.99992 51.5 8.77606 51.5 8.49992C51.5 8.22378 51.2761 7.99992 51 7.99992V8.99992ZM8.64645 0.146445L0.646448 8.14636L1.35355 8.85347L9.35355 0.853555L8.64645 0.146445ZM0.646445 8.85347L8.64644 16.8536L9.35356 16.1464L1.35356 8.14637L0.646445 8.85347ZM1 8.99992H51V7.99992H1V8.99992Z"
                  fill="#8E8E8E"
                />
              </svg>
            </button>
            <button ref={navigationNextRef}>
              <svg width="100" height="25" viewBox="0 0 51 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M51 8.50008L51.3536 8.85364L51.7071 8.50009L51.3536 8.14653L51 8.50008ZM1 8.00008C0.723858 8.00008 0.5 8.22394 0.5 8.50008C0.5 8.77622 0.723858 9.00008 1 9.00008V8.00008ZM43.3536 16.8536L51.3536 8.85364L50.6464 8.14653L42.6464 16.1464L43.3536 16.8536ZM51.3536 8.14653L43.3536 0.146448L42.6464 0.853552L50.6464 8.85363L51.3536 8.14653ZM51 8.00008L1 8.00008V9.00008L51 9.00008V8.00008Z"
                  fill="#8E8E8E"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gellery;
