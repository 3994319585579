import React, { useEffect, useState } from "react";
import Home from "./components/Home";
import "./styles/index.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TarrifsAlteg from "./components/TarrifsAlteg";
import RecordAlteg from "./components/RecordAlteg";

const App: React.FC = () => {
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadBody, setIsLoadBody] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 4000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoadBody(false);
    }, 100);
  }, []);
  return (
    <div className="app">
      {isLoad === true ? (
        <div className="loaderBody">
          <div className="loading">
            <svg width="64px" height="48px">
              <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="back"></polyline>
              <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="front"></polyline>
            </svg>
          </div>
          <div className="loader">
            <span className="l">L</span>
            <span className="o">o</span>
            <span className="a">a</span>
            <span className="d">d</span>
            <span className="i">i</span>
            <span className="n">n</span>
            <span className="g">g</span>
            <span className="d1">.</span>
            <span className="d2">.</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isLoadBody === true ? (
        <></>
      ) : (
        <Router>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/record" element={<RecordAlteg />} />
            <Route path="/tarrifs" element={<TarrifsAlteg />} />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default App;
