import Iframe from "react-iframe";

const RecordAlteg = () => {
  return (
    <div className="RecordAlteg" style={{ width: "100%", height: "100vh" }}>
      <Iframe
        url="https://n1036499.alteg.io/company/983965/personal/select-time?o=m2708414s12119274"
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        position="relative"
      />
    </div>
  );
};

export default RecordAlteg;
