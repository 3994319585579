import React, { useRef } from "react";
import "./Benefits.scss";
import "../Benefits/BenefitsAdaptive.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

interface Benefit {
  id: number;
  title: string;
  text: string;
}

const Benefits: React.FC = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <div className="Benefits">
      <div className="Benefits_Body">
        <div className="BenefitsTitleBody" id="about">
          <h2 className="BenefitsTitle">
            <span>На</span>ші переваги
          </h2>
        </div>
        <div className="benefits__items">
          <Swiper
            speed={500}
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            className="benefits_slider"
            loop={true}
            modules={[Navigation]}
            navigation={{
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            <SwiperSlide className="benefits_body">
              <div className="benefits_group">
                {benefits_1.map((item, i) => (
                  <div className="benefits_item">
                    <div className="benefits_item_title">
                      <span>{item.id}</span> <p>{item.title}</p>
                    </div>
                    <div className="benefits_item_text">
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </SwiperSlide>
            <SwiperSlide className="benefits_body">
              <div className="benefits_group">
                {benefits_2.map((item, i) => (
                  <div className="benefits_item">
                    <div className="benefits_item_title">
                      <span>{item.id}</span> <p>{item.title}</p>
                    </div>
                    <div className="benefits_item_text">
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="benefits__navigation">
          <button ref={navigationPrevRef}>
            <svg width="100" height="25" viewBox="0 0 52 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 8.49992L0.646448 8.14636L0.292893 8.49991L0.646445 8.85347L1 8.49992ZM51 8.99992C51.2761 8.99992 51.5 8.77606 51.5 8.49992C51.5 8.22378 51.2761 7.99992 51 7.99992V8.99992ZM8.64645 0.146445L0.646448 8.14636L1.35355 8.85347L9.35355 0.853555L8.64645 0.146445ZM0.646445 8.85347L8.64644 16.8536L9.35356 16.1464L1.35356 8.14637L0.646445 8.85347ZM1 8.99992H51V7.99992H1V8.99992Z"
                fill="#8E8E8E"
              />
            </svg>
          </button>
          <button ref={navigationNextRef}>
            <svg width="100" height="25" viewBox="0 0 51 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M51 8.50008L51.3536 8.85364L51.7071 8.50009L51.3536 8.14653L51 8.50008ZM1 8.00008C0.723858 8.00008 0.5 8.22394 0.5 8.50008C0.5 8.77622 0.723858 9.00008 1 9.00008V8.00008ZM43.3536 16.8536L51.3536 8.85364L50.6464 8.14653L42.6464 16.1464L43.3536 16.8536ZM51.3536 8.14653L43.3536 0.146448L42.6464 0.853552L50.6464 8.85363L51.3536 8.14653ZM51 8.00008L1 8.00008V9.00008L51 9.00008V8.00008Z"
                fill="#8E8E8E"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

const benefits_1: Benefit[] = [
  {
    id: 1,
    title: "Сучасне обладнання",
    text: "Ми використовуємо передові технології в області лазерної епіляції, що гарантує безпечність і максимальну ефективність процедур.",
  },
  {
    id: 2,
    title: "Професійна команда",
    text: "Наші досвідчені спеціалісти забезпечать вам високий рівень обслуговування та індивідуальний підхід.",
  },
  {
    id: 3,
    title: "Зручність та гнучкість",
    text: "За допомогою нашого онлайн календаря для запису на візит, плануйте відвідування в зручний для вас час.",
  },
];
const benefits_2: Benefit[] = [
  {
    id: 4,
    title: "Якісні продукти",
    text: "Ми використовуємо передові технології в області лазерної епіляції, що гарантує безпечність і максимальну ефективність процедур.",
  },
  {
    id: 5,
    title: "Індивідуальний підхід",
    text: "Ми використовуємо передові технології в області лазерної епіляції, що гарантує безпечність і максимальну ефективність процедур.",
  },
  {
    id: 6,
    title: "Доступні ціни",
    text: "Ми використовуємо передові технології в області лазерної епіляції, що гарантує безпечність і максимальну ефективність процедур.",
  },
];
