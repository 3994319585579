import React, { useEffect, useRef, useState } from "react";
import "../Header/Header.scss";
import "../Header/HeaderAdaptive.scss";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const modal = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (modal) {
      if (!menuOpen) {
        console.log(modal);
        setTimeout(() => {
          modal.current?.classList.add("closed");
        }, 0);
      } else if (menuOpen) {
        setTimeout(() => {
          modal.current?.classList.remove("closed");
        }, 0);
      }
    }
  };

  const btnsvgstyle: React.CSSProperties = {
    fontSize: "20px",
    width: "23px",
    height: "23px",
    marginRight: "11px",
  };

  const scrollToAbout = () => {
    const aboutElement = document.getElementById("about") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTariffs = () => {
    const aboutElement = document.getElementById("tarrifs") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFooter = () => {
    const aboutElement = document.getElementById("footer") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1100) {
      setIsMenu(false);
    }
    if (width <= 1100) {
      setIsMenu(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="header__top">
          <nav className={`nav `}>
            <div className={`nav__logo `}>
              <img src={process.env.PUBLIC_URL + "/logo.svg"} alt="" />
            </div>
            <div className="nav__menu">
              <ul>
                <li>
                  <p onClick={scrollToAbout}>Про нас</p>
                </li>
                <li>
                  <p onClick={scrollToTariffs}>Тарифи</p>
                </li>
                <li>
                  <p onClick={scrollToFooter}>Контакти</p>
                </li>
              </ul>
            </div>
            <div className="nav__contanct">
              <div className="nav__contanct-number">
                <a href="tel:+380123456789">+38(012)-345-67-89</a>
              </div>
              <Link to="/record" style={{ color: "white" }}>
                <div className="nav__contanct-btn">
                  <img src={process.env.PUBLIC_URL + "/headerSVG.svg"} alt="men" style={btnsvgstyle} />
                  <button>Особистий кабінет</button>
                </div>
              </Link>
              {isMenu && (
                <button className="nav__burger" onClick={toggleMenu}>
                  <img src="./burger_nav.svg" alt="" />
                </button>
              )}
            </div>
            <nav className="sidenav closed" ref={modal}>
              <div className="sidenav__menu">
                <ul>
                  <li>
                    <p onClick={scrollToAbout}>Про нас</p>
                  </li>
                  <li>
                    <p onClick={scrollToTariffs}>Тарифи</p>
                  </li>
                  <li>
                    <p onClick={scrollToFooter}>Контакти</p>
                  </li>
                </ul>
              </div>
            </nav>
          </nav>
        </div>
        <div className="header__content">
          <div className="header__content-text">
            <div className="header__content-span">
              <span>Ми пропонуємо</span>
            </div>
            <div className="header__content-title">
              <p>Найкращі послуги у сфері лазерної епіляції</p>
            </div>
            <div className="header__content-btn">
              <Link to="/record" style={{ color: "white" }}>
                <button>Оформити підписку</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
