import Iframe from "react-iframe";

const TarrifsAlteg = () => {
  return (
    <div className="TarrifsAlteg" style={{ width: "100%", height: "100vh" }}>
      <Iframe
        url="https://o2152.alteg.io/loyalty"
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        position="relative"
      />
    </div>
  );
};

export default TarrifsAlteg;
